<template>
  <div class="w-100 py-5 bg-white">
    <div class="container-fluid px-5">
      <div class="row row-cols-1 row-cols-md-3 g-4">
        <div v-for="project in projects" :key="project.id" class="col">
          <router-link :to="`/project/${project.url}`" class="text-decoration-none">
          <div class="card h-100 shadow-sm article-card">
            <img :src="getImageUrl(project.image)" class="card-img-top img-fluid" alt="..." />  
            <div class="card-body">
              <h5 class="card-title">{{ project.name }}</h5>
              <p class="card-text text-muted">{{ project.description }}</p>
            </div>
          </div>
        </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiFetch } from '@/utils/api.js';
import { useHead } from '@vueuse/head'
export default {
  data() {
        useHead({
          title: 'Mebelshik - Головна сторінка',
          meta: [
            {
              name: 'description',
              content: 'Изготовление мебели по индивидуальным проектам',
            },
            {
              name: 'keywords',
              content: 'мебель, заказ, интерьер, шкаф, кухня',
            },
          ],
        })
    return {
      projects: [],  // Список проектов
      currentPage: 1,  // Текущая страница
      totalPages: 1,   // Общее количество страниц
      limit: 20,        // Количество проектов на странице
    };
  },
  methods: {
      async fetchProjects() {
        try{
          const data = await apiFetch(`/project/?page=${this.currentPage}&limit=${this.limit}`);
          console.log(data);
          this.projects = data.projects;
          this.totalPages = data.totalPages;
          this.currentPage = data.currentPage;

        }catch(err){
          console.error(err)
        }
      },
     
      changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchProjects();
      }
    },
      openProject(projectId) {
        this.$router.push(`/project/${projectId}`);
      },
      getImageUrl(imagePath) {
        return `${process.env.VUE_APP_API_URL}${imagePath}`;
      },
    },
    async mounted() {
      await this.fetchProjects();
      console.log(this.projects);
    },
};
</script>

<style scoped>
.article-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.article-card:hover {
  transform: scale(1.03);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  z-index: 2;
}
</style>
