<template>
  <div>
    <!-- Показываем спиннер, пока isUploading = false -->
    <div v-if="!isUploading" class="text-center mt-5">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>

    <!-- Показываем компонент, когда isUploading = true -->
    <Project 
      v-else
      :data="dataFromServer"
      :ViewLeftMenu="ViewLeftMenu"
    />
  </div>
</template>

<script>
import Project from "./ProjectComponent.vue";
import { apiFetch } from '@/utils/api.js';


export default {
  props: ['url'],
  components: {
    Project
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      isUploading:false,
      // Инициализируем dataFromServer как пустой объект
      dataFromServer: {
        project: null,
        block: [],
        materials: [],
        extrusion: [],
        fastener: [],
        hierarchy: [],
        layer: [],
        line3D: [],
        panels: [],
        size: []
      },
      ViewLeftMenu:{
        light:true,
        materials:true,
        blocks:false,
        panels:false,
        save:false,
        saveMaterial:false
      }
    };
  },
  async mounted() {
    // Здесь уже все инициализировано, так что можно не присваивать снова пустые массивы
    this.dataFromServer.project = await this.getProject();
    const id = this.dataFromServer.project.id;
    await this.fetchMaterials(id);
    await this.fetchPanels(id);
    await this.fetchExtrusions(id);
    await this.fetchBlocks(id);
    this.isUploading = true;
   
  },
  methods: {
    // твои методы здесь, если нужно
    async getProject(){
      try{
        const result = await apiFetch('/project/'+this.url);
        return result;
      }catch(err){
        console.log(err);
      }
    },
    async fetchMaterials(id){
      try {
        const response = await apiFetch('/projectmaterial/'+id);
        this.dataFromServer.materials = response;
      } catch (error) {
        console.error('Error fetching materials:', error);
      }
    },
    async fetchPanels(id){
      try {
        const response = await apiFetch('/panel/project/'+id);
        this.dataFromServer.panels= response;
      } catch (error) {
        console.error('Error fetching panels:', error);
      }
    },
    async fetchExtrusions(id){
      try {
        const response = await apiFetch('/extrusion/project/'+id);
        this.dataFromServer.extrusion= response;
      } catch (error) {
        console.error('Error fetching panels:', error);
      }
    },
    async fetchBlocks(id){
      try {
        const response = await apiFetch('/block/'+id);
        this.dataFromServer.block = response;
      } catch (error) {
        console.error('Error fetching blocks:', error);
      }
    }
  },
};
</script>


