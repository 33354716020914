<template>  
    <div>
        
    </div>

    <div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasBlocks" aria-labelledby="offcanvasBlocksLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasBlocksLabel">Блоки</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <div class="list-group">
            <div 
              v-for="(item, index) in filteredBlockMeshes" 
              :key="index" 
             
              @click="handleClick(item)"           
              class="list-group-item list-group-item-action">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{ item.userData.name }}</h5>
                <small class="text-body-secondary">{{ item.userData.datumMode }}</small>
              </div>
            </div>
          </div>
          </div>
      </div>
</template>

<script>
import * as THREE from "three";
export default {
  emits: ['addBlocksToScene'], // Добавляем событие в список разрешённых
  props: {
    data: Array, 
  },
  data() {
    return {
      blocks:null,
      blockMeshes:[], 
    };
  },
  watch: {
    data: {
      handler(newVal) {
        console.log("Data изменилось:", newVal);
      },
      deep: true, // Следить за изменениями внутри массива
    }
  },
  mounted() {
    //console.log("Начальные данные:", this.data);
    this.addBlocksToScene(this.data);
    this.organizeBlockMeshes(this.blockMeshes);
    //console.log(this.blockMeshes);
    this.returnData(this.blockMeshes);
  },
  methods: {
    addBlocksToScene(blocks) {
        const scale = 1000;
        // Добавляем новые панели
        blocks.forEach((block) => {
            const geometry = new THREE.BoxGeometry( block.length/scale, block.width/scale,block.depth/scale ); 
            const edgesGeometry = new THREE.EdgesGeometry(geometry);
            edgesGeometry.translate(block.centerX/scale,block.centerY/scale,block.centerZ/scale);
            const edgesMaterial = new THREE.LineBasicMaterial({ color: 0x00AA00 }); // Цвет контуров
            const edges = new THREE.LineSegments(edgesGeometry, edgesMaterial);   
            edges.visible = false;

            const group = new THREE.Group();
            group.userData.uid = block.uid;
            group.userData.ownerId = block.ownerId;
            group.userData.name = block.name;
            group.userData.datumMode = block.datumMode;
            group.add(edges);
            
            group.position.set(block.positionX/scale,block.positionY/scale,block.positionZ/scale);
            const quaternion = new THREE.Quaternion(block.rotX, block.rotY, block.rotZ, block.rotW);
            group.quaternion.copy(quaternion);  
            //this.scene.add( group );
            this.blockMeshes.push(group);
            })
    },
    organizeBlockMeshes(blockMeshes) {
        const uidMap = new Map(); // Карта для быстрого доступа к группам
        const rootGroups = [];   // Список корневых групп
      
        // Сначала добавляем все группы в карту
        blockMeshes.forEach(mesh => {
            const uid = mesh.userData.uid;
            uidMap.set(uid, mesh);
            // Добавляем в корневой массив, если нет родителя
            if (mesh.userData.ownerId===1) {
                rootGroups.push(mesh);
                //this.$emit('addBlockToScene', mesh);  // Отправляем данные родителю
            }
        });
     
        // Теперь добавляем группы в их родительские группы
        blockMeshes.forEach(mesh => {
            const ownerId = mesh.userData.ownerId;
            if (ownerId && uidMap.has(ownerId)) {
                const parentGroup = uidMap.get(ownerId);
                parentGroup.add(mesh); // Добавляем текущую группу в родительскую
            }
        })
     
  },
  handleClick(item) {
    // Действие при клике на элемент
    console.log('Вы нажали на', item);
    console.log(this.blockMeshes);
    this.blockMeshes.forEach((block)=>{
      block.visible = false; // Скрываем все блоки
      block.children.forEach(child => {
        if (child.id == item.id){
          block.visible = true; // Делаем видимым выбранный блок
        }
      });
    })
    item.visible = true; // Делаем видимым выбранный блок
    item.children.forEach(child => {
      child.visible = true; // Делаем видимым выбранный блок
    });
    /*
    this.blockMeshes.forEach((blockMesh) => {
        if (blockMesh.userData.id === item.userData.id) {
            blockMesh.children.forEach(child => {
                child.visible = true; // Делаем видимыми всех детей выбранного элемента
            });
            blockMesh.visible = true; // Делаем видимым сам блок
        } else {
            blockMesh.children.forEach(child => {
                child.visible = false; // Скрываем всех детей остальных элементов
            });
            blockMesh.visible = false; // Скрываем остальные блоки
        }
    });
    */
},


  handleMouseEnter(item) {
    item.children[0].visible=true;
    
  },
  handleMouseLeave(item) { 
    item.children[0].visible=false;
  },

    returnData(blockMeshes){
        this.$emit('addBlocksToScene', blockMeshes);  // Отправляем данные родителю
  }
  },
  computed: {
  filteredBlockMeshes() {
    return this.blockMeshes
      .filter(item => item.userData.datumMode === 0)
      .sort((a, b) => a.userData.name.localeCompare(b.userData.name));
  }
}
  /*
  computed: {
  filteredBlockMeshes() {
    return this.blockMeshes
      .filter(item => item.userData.datumMode === 0)
      .sort((a, b) => a.userData.name.localeCompare(b.userData.name));
  }
}*/
}
</script>
<style>
.highlight {
    background-color: lightgray;
    cursor: pointer;
  }
</style>
