<template>  
  <div>
    <LeftMenuComponent
    @saveToServer="saveToServer" 
    :ViewLeftMenu="ViewLeftMenu"
    
    />
    <LightComponent
      @lightSetting="lightSetting"
      :data="lightData"
    />
    <BlocksComponent 
      v-if="blocks!=null"
      :data="blocks"
      @addBlocksToScene="addBlocksToScene"
    />
    <MaterialsComponent
      v-if="materials!=null"
      :data="materials"
      :saveMaterial="ViewLeftMenu.saveMaterial"
      @updateMaterial="updateMaterial"
    />
    <PanelsComponent
    v-if="panels!=null"
    :data="panels"
    @addPanelToScene="addPanelToScene"
    />
    <ExtrudionsComponent
    v-if="extrusions!=null"
    :data="extrusions"
    @addExtrusionToScene="addExtrusionToScene"
    />

    <SaveComponent
    v-if="saveData"
    :data="saveData"
    @closeSave="closeSave"
    
    />

  <div ref="sceneContainer" class="three-scene"></div>
 
  </div>
   
   
</template>

import blocksVue from "./components/blocks.vue";
<script>
import { useHead } from '@vueuse/head'
import { toRaw } from "vue";
import * as THREE from "three";
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"; // Импорт OrbitControls

import LightComponent from "./components/LightComponent.vue";
import BlocksComponent from "./components/BlocksComponent.vue";
import MaterialsComponent from "./components/MaterialsComponent.vue";
import LeftMenuComponent from "./components/leftMenu.vue";
import PanelsComponent from "./components/PanelComponent.vue";
import ExtrudionsComponent from "./components/ExtrusionComponent.vue";
import SaveComponent from "./components/SaveComponent.vue";

export default {
  components: {
    LightComponent,
    BlocksComponent,
    MaterialsComponent,
    PanelsComponent,
    LeftMenuComponent,
    ExtrudionsComponent,
    SaveComponent
  },
  
  props: {
    data: {
      type: Object,
      default: () => ({}) // Возвращаем пустой объект по умолчанию
    },
    ViewLeftMenu: {
      type: Object,
      default: () => ({
        light: true,
        materials: true,
        blocks: true,
        panels: true,
        save: true
      })
    }
  },
  data() {
    useHead({
  title: 'Mebelshik - ' + this.data.project.name,
  meta: [
    {
      name: 'description',
      content: this.data.project.description,
    },
    {
      name: 'keywords',
      content: 'мебель, заказ, интерьер, шкаф, кухня',
    },
    // Open Graph tags for Facebook and other social platforms
    {
      property: 'og:title',
      content: 'Mebelshik - ' + this.data.project.name,
    },
    {
      property: 'og:description',
      content: this.data.project.description,
    },
    {
      property: 'og:image',
      content: process.env.VUE_APP_API_URL+this.data.project.image, // Укажите URL изображения для превью
    },
    {
      property: 'og:url',
      content: window.location.href, // Это текущий URL страницы
    },
    {
      property: 'og:type',
      content: 'website',
    },
    // Twitter Card tags
    {
      name: 'twitter:title',
      content: 'Mebelshik - ' + this.data.project.name,
    },
    {
      name: 'twitter:description',
      content: this.data.project.description,
    },
    {
      name: 'twitter:image',
      content: process.env.VUE_APP_API_URL+this.data.project.image, // Укажите URL изображения для превью
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image', // Карта с большим изображением
    },
  ],
});

    return {
      globalHeaderFooterHeight: localStorage.getItem("globalHeaderFooterHeight"),
      apiUrl: process.env.VUE_APP_API_URL,
      isLoading:false,
      blocks:null,
      blockMeshes:null,  
      materials:null,  
      panels:null,
      saveData:null,
      lightData:null,
      extrusions:null,
     
    };
  },
  mounted() {
    console.log('Загруженніе данніе');
    console.log(this.data);
    this.lightData = this.data.project;
    // Обычные свойства компонента
    this.scene = null;
    this.camera = null;
    this.renderer = null;
    this.controls = null; // Для OrbitControls
    this.panelMeshes = []; // Панели в сцене
    this.extrusionsMeshes = []; //тела выдавливания
    //this.blockMeshes = [];
    this.materialMeshes = [];
    this.LineGroup=null;
    this.light = null;
    this.ambientLight = null;
    this.initThree();
    this.startData();
  },
  methods: {
    startData(){
      console.log('START DATA');
      console.log(this.data);
      this.blocks = this.data.block;
      this.materials=this.data.materials;
      this.panels = this.data.panels;
      this.extrusions = this.data.extrusion;
      
    },
    saveToServer(){
      this.renderer.render(this.scene, this.camera);
      // Получаем URL изображения в формате Base64
      const screenshotURL = this.renderer.domElement.toDataURL("image/png");
      const cameraState = {
        position: {
          x: this.camera.position.x,
          y: this.camera.position.y,
          z: this.camera.position.z
        },
        target: {
          x: this.controls.target.x,
          y: this.controls.target.y,
          z: this.controls.target.z
        }
      };
      const lightState = {
        intensity: this.light.intensity,
        ambient: this.ambientLight.intensity,
        rotationAngle: this.scene.environmentRotation.y,
        exposure: this.renderer.toneMappingExposure
      };
      this.saveData={
        materials:this.materials,
        panels:this.panels,
        blocks:this.blocks,
        camera:cameraState,
        light:lightState,
        extrusions:this.extrusions,
        screenshot:screenshotURL,
      }
    },
    lightSetting(light){

      this.renderer.toneMappingExposure = light.exposure;
      this.light.intensity = light.intensity;
      this.ambientLight.intensity = light.ambient;
      this.scene.environmentRotation.y = light.rotationAngle;
      const radius = 5;
        const x = Math.sin(light.rotationAngle) * radius;
        const z = Math.cos(light.rotationAngle) * radius;
        this.light.position.set(x, 5, z);
        this.light.lookAt(0, 0, 0);
    },
    closeSave(){
      this.saveData = null;
    },
    initCamera(){
      console.log("----------------Инициализация камеры------------------------");
      //console.log(this.data.project);
      if (this.data.project) {
        const posX = this.data.project.cameraPosX ?? 0;
        const posY = this.data.project.cameraPosY ?? 0;
        const posZ = this.data.project.cameraPosZ ?? 5;
        const lookAtX = this.data.project.cameraLookAtX ?? 0;
        const lookAtY = this.data.project.cameraLookAtY ?? 0;
        const lookAtZ = this.data.project.cameraLookAtZ ?? 0;

        // Установка позиции камеры
        this.camera.position.set(posX, posY, posZ);

        // Установка точки, на которую смотрит камера
        this.controls.target.set(lookAtX, lookAtY, lookAtZ);
        this.controls.update(); // Не забываем обновить controls
      } else {
        console.error("Данные проекта не найдены");
        this.camera.position.set(0, 5, 5);
        this.controls.target.set(0, 0, 0);
        this.controls.update();
        
      }
      //console.log("Camera position:", this.camera.position);
      //console.log("Controls target:", this.controls.target);
    },

    initLight() {
      //console.log("----------------Инициализация света------------------------");
      this.light = new THREE.DirectionalLight(0xffffff, 1);
      this.light.position.set(0, 5, 6);
      this.light.castShadow = true; // Включаем отбрасывание теней от света

      // Настройки теней для источника света
      this.light.shadow.mapSize.width = 1024; // Разрешение карты теней
      this.light.shadow.mapSize.height = 1024;
      this.light.shadow.camera.near = 1; // Ближайшая видимая точка
      this.light.shadow.camera.far = 15; // Дальняя видимая точка
      this.light.shadow.camera.left = -10; // Границы проекции света (для DirectionalLight)
      this.light.shadow.camera.right = 10;
      this.light.shadow.camera.top = 10;
      this.light.shadow.camera.bottom = -10;

      this.scene.add(this.light);

    },

    initThree() {
      //console.log("----------------Инициализация Three.js------------------------");
      //console.log(this.data);

      const container = this.$refs.sceneContainer;
      const width = container.clientWidth;
      const height = window.innerHeight - this.globalHeaderFooterHeight;

      this.scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
       

      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(width, height);
      this.renderer.setClearColor(0xffffff);
      this.renderer.physicallyCorrectLights = true;
      this.renderer.outputColorSpace = THREE.SRGBColorSpace;
      this.renderer.shadowMap.enabled = true; // Включаем поддержку теней
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Тип тени (мягкие тени)
      this.renderer.gammaOutput = true;
      this.renderer.gammaFactor = 2.2;
      this.renderer.outputEncoding = THREE.sRGBEncoding;

      this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 1.2;
      container.appendChild(this.renderer.domElement);

      // Инициализация OrbitControls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true; // Для плавности
      this.controls.dampingFactor = 0.05;
      
      this.initCamera();
      
      // Загрузка HDR окружения
      const rgbeLoader = new RGBELoader();
      const URI = this.apiUrl + "/uploads/neon_photostudio_1k.hdr";
      rgbeLoader.load(
        URI, // Путь к вашему HDR-файлу
        (texture) => {
          texture.mapping =  THREE.EquirectangularReflectionMapping ; // Настройка типа отображения
          this.environmentTexture = texture;
          this.scene.environment = texture; // Установка HDR как окружения сцены
          //this.scene.background = texture; // Установка HDR как фона сцены
        },
        undefined,
        (error) => {
          console.error('Ошибка при загрузке HDR:', error);
        }
      );

        
      this.initLight();

      //const helper = new THREE.CameraHelper(this.light.shadow.camera);
      //this.scene.add(helper); // Визуализируем камеру теней для отладки

      this.ambientLight = new THREE.AmbientLight( 0xFFFFFF ,1); // soft white light
      this.scene.add( this.ambientLight );

      // Плоскость для приёма теней
      const planeGeometry = new THREE.PlaneGeometry(20, 20);
      const planeMaterial = new THREE.ShadowMaterial({ opacity: 0.5 }); // Тени станут полупрозрачными
      const plane = new THREE.Mesh(planeGeometry, planeMaterial);
      plane.rotation.x = -Math.PI / 2; // Поворачиваем плоскость горизонтально
      plane.position.y = 0;
      plane.receiveShadow = true; // Плоскость принимает тени
      this. scene.add(plane);
     

      
      
     

      this.animate();

      window.addEventListener("resize", this.onWindowResize);
    },
    addBlocksToScene(blocks) {
      //console.log("Добавляю блок на сцену");
      blocks.forEach(block=>{
        if (block.parent == null){
          const rawBlock = toRaw(block); // Убираем реактивность Vue
          //console.log(rawBlock);

          if (rawBlock instanceof THREE.Object3D) {
              this.scene.add(rawBlock);
            } else {
              console.error("Ошибка: объект не является THREE.Object3D", rawBlock);
          }

        }})

     this.blockMeshes=blocks; 
},
    addPanelToScene(panel){
      //console.log("Add panel to scene");
      //console.log(panel);
      //console.log(this.blockMeshes);
      this.blockMeshes.forEach(block=>{
          if (block.userData.uid==panel.ownerId){
            block.add(panel.mesh);
            block.add(panel.edges);
          }
        })
        if (panel.ownerId==1){
          this.scene.add(panel.mesh);
          this.scene.add(panel.edges);
        }
    },
    addExtrusionToScene(extrusion){
      //console.log("Add extrusion to scene");
      //console.log(extrusion);
      this.blockMeshes.forEach(block=>{
          if (block.userData.uid==extrusion.ownerId){
            block.add(extrusion.mesh);
            block.add(extrusion.edges);
          }
        })
        if (extrusion.ownerId==1){
          this.scene.add(extrusion.mesh);
          this.scene.add(extrusion.edges);
        }
    },
    updateMaterial(material) {
    //console.log("updateMaterial");
    //console.log(material);
    this.scene.traverse((object) => {
       // console.log("Проверяем mesh");
       // console.log(object.userData);
        if (object.isMesh && object.userData.numOfMaterial == material.numOfMaterial) {
            //console.log("Замена материала у ");
            //console.log(object.userData);
            
            // Удаляем старый материал
            object.material.dispose();
            object.material = material.threeMaterial;
            
            if (object.userData.textureOrientation === 2 ) {
              object.material = material.threeMaterial;
            }else{
              object.material = material.threeMaterial90;
            }

            object.material.needsUpdate = true; // Указываем, что материал обновлен
        }
    });
},


    animate() {
      requestAnimationFrame(this.animate);
      
      // Обновление OrbitControls
      if (this.controls) {
        this.controls.update();
      }

      this.renderer.render(this.scene, this.camera);
    },
    onWindowResize() {
      const container = this.$refs.sceneContainer;
      console.log(container);
      if (container != null) {
        const width = container.clientWidth;
        const height = window.innerHeight - this.globalHeaderFooterHeight;
        this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);

      }
     

    
    },
    
  },
}


</script>


<style scoped>
.three-scene {
  width: 100%;
  height: 100%; /* Занимает все доступное пространство */
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

</style>
