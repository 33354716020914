<template>  
    <div>
        
    </div>

    <div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasPanels" aria-labelledby="offcanvasPanelsLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasPanelsLabel">Панели</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="list-group">
                <div 
                v-for="(item, index) in panels" 
                :key="index" 
                @mouseenter="handleMouseEnter(item)" 
                @mouseleave="handleMouseLeave(item)"
                @click="handleClick(item)"           
                class="list-group-item list-group-item-action">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{ item.name }}</h5>
                    <small class="text-body-secondary">3 days ago</small>
                  </div>
                </div>
              </div>
          </div>
      </div>
</template>

<script>
import * as THREE from "three";
export default {
  emits: ['addPanelToScene'], // Добавляем событие в список разрешённых
  props: {
    data: Array, 
    
  },
  data() {
    return {
      panels:[],
    };
  },
  watch: {
    data: {
      handler(newVal) {
        console.log("Data изменилось:", newVal);
      },
      deep: true, // Следить за изменениями внутри массива
    }
  },
  mounted() {
    //console.log("Панели Начальные данные:", this.data);
   this.addPanelsToScene(this.data);
    //console.log(this.blockMeshes);
    //this.returnData(this.blockMeshes);
  },
  methods: {

    splitContoursToLoops(segments) {
      const remaining = [...segments];
      const loops = [];

      while (remaining.length > 0) {
        const loop = [];
        let current = remaining.shift();
        loop.push(current);

        // Начальная точка текущего контура
        let currentEnd = { x: current.pos2x, y: current.pos2y };

        let closed = false;
        while (!closed && remaining.length > 0) {
          const nextIndex = remaining.findIndex(seg =>
            Math.abs(seg.pos1x - currentEnd.x) < 0.001 &&
            Math.abs(seg.pos1y - currentEnd.y) < 0.001
          );

          if (nextIndex === -1) {
            // Не нашли продолжение — прерываем (контур может быть незамкнут)
            break;
          }

          const next = remaining.splice(nextIndex, 1)[0];
          loop.push(next);
          currentEnd = { x: next.pos2x, y: next.pos2y };

          // Проверка замыкания
          const first = loop[0];
          if (Math.abs(currentEnd.x - first.pos1x) < 0.001 &&
              Math.abs(currentEnd.y - first.pos1y) < 0.001) {
            closed = true;
          }
        }

        loops.push(loop);
      }

      return loops;
    },

    calculateArea(loop) {
        let area = 0;
        for (let i = 0; i < loop.length; i++) {
          const current = loop[i];
          const nextPoint = loop[(i + 1) % loop.length];

          area += (current.pos1x * nextPoint.pos1y - nextPoint.pos1x * current.pos1y);
        }
        return Math.abs(area / 2);
      },

  // Функция для разделения контуров на внешний и внутренние
splitOuterInnerContours(loops) {
      // Считаем площадь каждого замкнутого контура
      const loopAreas = loops.map(loop => ({
        loop,
        area: this.calculateArea(loop)
      }));

      // Сортируем по убыванию площади — первый будет внешний
      loopAreas.sort((a, b) => b.area - a.area);

      const outer = loopAreas[0].loop;
      const inner = loopAreas.slice(1).map(entry => entry.loop);

      return { outer, inner };
},


    

    // Добавляем панели в сцену
    addPanelsToScene(panels) {
    const scale = 1000;

    panels.forEach((panel) => {
        const shape = new THREE.Shape();

        const ccc = this.splitContoursToLoops(panel.contours);
        const {outer, inner} = this.splitOuterInnerContours(ccc);
        //console.log("Внешний контур:", outer);
       

        outer.forEach((contour) => {
            
            if (contour.type === 1) {
                // Прямая линия
                shape.moveTo(contour.pos1x / scale, contour.pos1y / scale);
                shape.lineTo(contour.pos2x / scale, contour.pos2y / scale);
            } else if (contour.type === 2) {
                //console.log('Строй радиус');
                //console.log(contour);
                // Дуга
                const startAngle = contour.startAngle;
                const endAngle = contour.endAngle;
                //const clockwise = contour.arcDir === 1; // 0 = против часовой, 1 = по часовой
                shape.absarc(
                    contour.centerX / scale,
                    contour.centerY / scale,
                    contour.radius / scale,
                    startAngle,
                    endAngle,
                    !contour.arcDir
                );
            } else if (contour.type === 3) {
                // Круг
                const centerX = contour.centerX / scale;
                const centerY = contour.centerY / scale;
                const radius = contour.radius / scale;
                shape.moveTo(centerX + radius, centerY); // Переходим в точку на окружности
                shape.absarc(centerX, centerY, radius, 0, Math.PI * 2, false); // Рисуем полный круг
            }
        });
        // Внутренние контуры (отверстия)
       
        //console.log('Внутренние контуры:', inner);
        
        if (inner.length > 0) {
          const hole = new THREE.Path();
            inner[0].forEach((contour) => {
              console.log('Контур:', contour);    
              if (contour.type === 1) {
                  // Прямая линия для отверстия
                  hole.moveTo(contour.pos1x / scale, contour.pos1y / scale);
                  hole.lineTo(contour.pos2x / scale, contour.pos2y / scale);
              } else if (contour.type === 2) {
                  // Дуга для отверстия
                  const startAngle = contour.startAngle;
                  const endAngle = contour.endAngle;
                  hole.absarc(
                      contour.centerX / scale,
                      contour.centerY / scale,
                      contour.radius / scale,
                      startAngle,
                      endAngle,
                      !contour.arcDir
                  );
              } else if (contour.type === 3) {
                  // Круг для отверстия
                  const centerX = contour.centerX / scale;
                  const centerY = contour.centerY / scale;
                  const radius = contour.radius / scale;
                  hole.moveTo(centerX + radius, centerY); // Переходим в точку на окружности
                  hole.absarc(centerX, centerY, radius, 0, Math.PI * 2, false); // Рисуем полный круг
              }
              
          });
          shape.holes.push(hole); // Добавляем отверстие в массив holes
        }
        
        // Создаем геометрию для тела панели
        const extrudeSettings = { depth: panel.thickness / scale, bevelEnabled: false };
        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
      

        const material = new THREE.MeshPhysicalMaterial();

          const mesh = new THREE.Mesh(geometry, material);
          mesh.castShadow = true; // Отбрасывает тени
          mesh.receiveShadow = true; // Принимает тени

        // Создаем контур геометрии
        const edgesGeometry = new THREE.EdgesGeometry(geometry);
        const edgesMaterial = new THREE.LineBasicMaterial({ color: 0x000000 }); // Цвет контуров
        const edges = new THREE.LineSegments(edgesGeometry, edgesMaterial);

        // Устанавливаем позицию панели
        mesh.position.set(panel.positionX / scale || 0, panel.positionY / scale || 0, panel.positionZ / scale || 0);
        edges.position.copy(mesh.position);

        // Поворачиваем панель (если есть quaternion)
        if (panel.rotW !== undefined) {
            const quaternion = new THREE.Quaternion(panel.rotX, panel.rotY, panel.rotZ, panel.rotW);
            mesh.quaternion.copy(quaternion);
            edges.quaternion.copy(quaternion);
        }
        mesh.userData.materialName = panel.materialName;
        mesh.userData.name = panel.name;
        mesh.userData.textureOrientation = panel.textureOrientation;
        mesh.userData.materialId = panel.materialId;
        mesh.userData.numOfMaterial = panel.numOfMaterial;
        //console.log('mesh');
        //console.log(panel);
        //console.log(mesh);

        const ownerId = panel.ownerId;
        this.panels.push({
            name:panel.name,
            ownerId:ownerId,
            mesh:mesh,
            edges:edges
        })
        this.$emit('addPanelToScene', {ownerId,mesh,edges});  // Отправляем данные родителю
    });
},
  handleClick(item) {
    // Действие при клике на элемент
    console.log('Вы нажали на', item.userData.name);
    // Здесь можно выполнить любое другое действие с item
    //console.log(item.children[0]);
  },
  handleMouseEnter(item) {
    //item.children[0].visible=true;
    console.log(item);
    
  },
  handleMouseLeave(item) { 
    //item.children[0].visible=false;
    console.log(item);
  },

   
  }
}
</script>
<style>
.highlight {
    background-color: lightgray;
    cursor: pointer;
  }
</style>
