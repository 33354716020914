<template>  
    <div>
    </div>
</template>

<script>
import * as THREE from "three";
export default {
  emits: ['addExtrusionToScene'], // Добавляем событие в список разрешённых
  props: {
    data: Array, 
  },
  data() {
    return {
      extrusions:[],
    };
  },
  watch: {
    data: {
      handler(newVal) {
        console.log("Data изменилось:", newVal);
      },
      deep: true, // Следить за изменениями внутри массива
    }
  },
  mounted() {
    console.log("Тела выдавливания начальные данные:", this.data);
    this.addExtrusionToScene(this.data);
    //console.log(this.blockMeshes);
    //this.returnData(this.blockMeshes);
  },
  methods: {
    addExtrusionToScene(extrusions) {
        const scale = 1000;
        extrusions.forEach((extr) =>{
            //console.log('extr');
            //console.log(extr);
            const shape = new THREE.Shape();
            extr.contours.forEach((contour) => {
                    if (contour.type === 1) {
                        // Прямая линия
                        //console.log('Прямая линия');
                        //console.log(contour);
                        shape.moveTo(contour.pos1x/scale, contour.pos1y/scale);
                        shape.lineTo(contour.pos2x/scale, contour.pos2y/scale);
                    } else if (contour.type === 2) {
                        //console.log('Строй радиус');
                        //console.log(contour);
                        // Дуга
                        const startAngle = contour.startAngle;
                        const endAngle = contour.endAngle;
                        //const clockwise = contour.arcDir === 1; // 0 = против часовой, 1 = по часовой
                        shape.absarc(
                            contour.centerX/scale,
                            contour.centerY/scale,
                            contour.radius/scale,
                            startAngle,
                            endAngle,
                            !contour.arcDir
                        );
                    }
            })
            // Создаем геометрию для тела панели
            const extrudeSettings = { depth: extr.thickness/scale, bevelEnabled: false };
            const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
            const material = new THREE.MeshPhysicalMaterial();
            const mesh = new THREE.Mesh(geometry, material);
            mesh.castShadow = true; // Отбрасывает тени
            mesh.receiveShadow = true; // Принимает тени
            // Создаем контур геометрии
            const edgesGeometry = new THREE.EdgesGeometry(geometry);
            const edgesMaterial = new THREE.LineBasicMaterial({ color: 0x000000 }); // Цвет контуров
            const edges = new THREE.LineSegments(edgesGeometry, edgesMaterial);
            // Устанавливаем позицию панели
            mesh.position.set(extr.positionX/scale || 0, extr.positionY/scale || 0, extr.positionZ/scale || 0);
            edges.position.copy(mesh.position);
            // Поворачиваем панель (если есть quaternion)
            if (extr.rotW !== undefined) {
                const quaternion = new THREE.Quaternion(extr.rotX, extr.rotY, extr.rotZ, extr.rotW);
                mesh.quaternion.copy(quaternion);
                edges.quaternion.copy(quaternion);
            }
            mesh.userData.materialName = extr.materialName;
            mesh.userData.name = extr.name;
            mesh.userData.textureOrientation = extr.textureOrientation;
            mesh.userData.materialId = extr.materialId;
            mesh.userData.numOfMaterial = extr.numOfMaterial;
            //console.log('mesh');
            //console.log(extr);
            const ownerId = extr.ownerId;
            this.extrusions.push({
                name:extr.name,
                ownerId:ownerId,
                mesh:mesh,
                edges:edges
            })
            this.$emit('addExtrusionToScene', {ownerId,mesh,edges});  // Отправляем данные родителю
        })
    },  

    addPanelsToScene(panels) {
    const scale = 1000;

    panels.forEach((panel) => {
        const shape = new THREE.Shape();
        panel.contours.forEach((contour) => {
            
            if (contour.type === 1) {
                // Прямая линия
                shape.moveTo(contour.pos1x / scale, contour.pos1y / scale);
                shape.lineTo(contour.pos2x / scale, contour.pos2y / scale);
            } else if (contour.type === 2) {
                //console.log('Строй радиус');
                //console.log(contour);
                // Дуга
                const startAngle = contour.startAngle;
                const endAngle = contour.endAngle;
                //const clockwise = contour.arcDir === 1; // 0 = против часовой, 1 = по часовой
                shape.absarc(
                    contour.centerX / scale,
                    contour.centerY / scale,
                    contour.radius / scale,
                    startAngle,
                    endAngle,
                    !contour.arcDir
                );
            }
        });
        // Создаем геометрию для тела панели
        const extrudeSettings = { depth: panel.thickness / scale, bevelEnabled: false };
        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
        /*
        const numOfMaterial = panel.numOfMaterial;
        let material = null;

       const findMaterial = this.materialMeshes.find(item => item.projectMaterial.num === numOfMaterial);
        console.log('-----------------Ищем материал-------------------------------');
        console.log('numOfMaterial: '+numOfMaterial);
        console.log('findMaterial');
        console.log(findMaterial);
        console.log('MaterialMeshes');
        console.log(this.materialMeshes);
        if (findMaterial) {
            if (panel.textureOrientation === 2) {
                material = findMaterial.material;
            } else {
                material = findMaterial.material90;
            }
        } else {
            console.error(`Материал с индексом ${numOfMaterial} не найден в this.materialMeshes.`);
        }*/

        const material = new THREE.MeshPhysicalMaterial();

          const mesh = new THREE.Mesh(geometry, material);
          mesh.castShadow = true; // Отбрасывает тени
          mesh.receiveShadow = true; // Принимает тени

        // Создаем контур геометрии
        const edgesGeometry = new THREE.EdgesGeometry(geometry);
        const edgesMaterial = new THREE.LineBasicMaterial({ color: 0x000000 }); // Цвет контуров
        const edges = new THREE.LineSegments(edgesGeometry, edgesMaterial);

        // Устанавливаем позицию панели
        mesh.position.set(panel.positionX / scale || 0, panel.positionY / scale || 0, panel.positionZ / scale || 0);
        edges.position.copy(mesh.position);

        // Поворачиваем панель (если есть quaternion)
        if (panel.rotW !== undefined) {
            const quaternion = new THREE.Quaternion(panel.rotX, panel.rotY, panel.rotZ, panel.rotW);
            mesh.quaternion.copy(quaternion);
            edges.quaternion.copy(quaternion);
        }
        mesh.userData.materialName = panel.materialName;
        mesh.userData.name = panel.name;
        mesh.userData.textureOrientation = panel.textureOrientation;
        mesh.userData.materialId = panel.materialId;
        mesh.userData.numOfMaterial = panel.numOfMaterial;
        //console.log('mesh');
        //console.log(panel);
        //console.log(mesh);

        const ownerId = panel.ownerId;
        this.panels.push({
            name:panel.name,
            ownerId:ownerId,
            mesh:mesh,
            edges:edges
        })
        this.$emit('addPanelToScene', {ownerId,mesh,edges});  // Отправляем данные родителю
    });
},
  handleClick(item) {
    // Действие при клике на элемент
    console.log('Вы нажали на', item.userData.name);
    // Здесь можно выполнить любое другое действие с item
    //console.log(item.children[0]);
  },
  handleMouseEnter(item) {
    //item.children[0].visible=true;
    console.log(item);
    
  },
  handleMouseLeave(item) { 
    //item.children[0].visible=false;
    console.log(item);
  },

   
  }
}
</script>
<style>
.highlight {
    background-color: lightgray;
    cursor: pointer;
  }
</style>
