<template>
  <div>
    <div class="input-group mb-3">
      <span class="input-group-text" id="void-material">Материал для отсутствуещего материала</span>
      <input
        type="text"
        class="form-control"
        v-model="voidMaterial"
        aria-describedby="void-material"
      />
    </div>
    <button class="btn btn-primary" @click="saveSetting">Сохранить</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      voidMaterial: '',
      apiUrl: process.env.VUE_APP_API_URL
    };
  },
  mounted() {
    this.fetchSetting();
  },
  methods: {
    async fetchSetting() {
      try {
        const res = await fetch(`${this.apiUrl}/setting/voidMaterial`);
        if (!res.ok) throw new Error('Не удалось получить настройку');
        const data = await res.json();
        this.voidMaterial = data.value;
      } catch (err) {
        console.error(err);
        // если настройки нет, просто ничего не делаем
      }
    },

    async saveSetting() {
      try {
        const res = await fetch(`${this.apiUrl}/setting`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            key: 'voidMaterial',
            value: this.voidMaterial,
            valueType: 'string',
          }),
        });

        if (!res.ok) throw new Error('Ошибка при сохранении');

        alert('Настройка сохранена!');
      } catch (err) {
        console.error(err);
        alert('Ошибка при сохранении');
      }
    }
  }
};
</script>
