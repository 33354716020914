import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createHead } from '@vueuse/head'; // 👈 для метатегов
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

// Переменные окружения
const apiUrl = process.env.VUE_APP_API_URL;
console.log(apiUrl);

// Создание приложения
const app = createApp(App);

// Создание head manager'а
const head = createHead();

// Подключение
app.use(router);
app.use(head); // 👈 обязательно
app.mount('#app');
