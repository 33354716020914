<template>
  <div class="container">
    <h4>Редактировать материал</h4>

    <div class="mb-3">
      <label class="form-label">Название</label>
      <input v-model="editedMaterial.alias" class="form-control" @input="emitUpdate" />
    </div>

    <div class="mb-3">
      <label class="form-label">Цвет</label>
      <input v-model="colorHex" type="color" class="form-control form-control-color" @input="emitUpdate" />
    </div>

    <div v-for="(setting, key) in sliders" :key="key" class="mb-3">
      <label class="form-label">{{ setting.label }} ({{ editedMaterial[key] }})</label>
      <input
        type="range"
        class="form-range"
        :min="setting.min"
        :max="setting.max"
        :step="setting.step"
        v-model.number="editedMaterial[key]"
        @input="emitUpdate"
      />
    </div>

    <!-- Добавляем загрузку текстуры -->
    <div class="mb-3">
      <label class="form-label">Загрузить текстуру</label>
      <input type="file" class="form-control" @change="onTextureUpload" accept="image/*" />
    </div>

    <button 
      v-if="saveMaterial"
      class="btn btn-primary" 
      @click="saveChanges">
      Сохранить
    </button>
  </div>
</template>

<script>
import { apiFetch } from '@/utils/api.js';
export default {
  name: "EditMaterial",
  props: {
    material: {
      type: Object,
      required: true,
      
    },
    saveMaterial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editedMaterial: { ...this.material },
      sliders: {
        roughness: { label: "Шероховатость", min: 0, max: 1, step: 0.01 },
        metalness: { label: "Металличность", min: 0, max: 1, step: 0.01 },
        reflectivity: { label: "Отражательность", min: 0, max: 1, step: 0.01 },
        opacity: { label: "Прозрачность", min: 0, max: 1, step: 0.01 },
        clearcoat: { label: "Лак (Clearcoat)", min: 0, max: 1, step: 0.01 },
        clearcoatRoughness: { label: "Шероховатость лака", min: 0, max: 1, step: 0.01 },
        ior: { label: "Индекс преломления (IOR)", min: 1, max: 2.5, step: 0.01 },
        envMapIntensity: { label: "Интенсивность окружения", min: 0, max: 5, step: 0.1 },
        transmission: { label: "Прозрачность (Transmission)", min: 0, max: 1, step: 0.01 },
      },
    };
  },
  computed: {
    colorHex: {
      get() {
        return "#" + this.editedMaterial.color.toString(16).padStart(6, "0");
      },
      set(value) {
        this.editedMaterial.color = parseInt(value.slice(1), 16);
      },
    },
  },
  methods: {
    async saveChanges() {
      console.log("Сохранение изменений", this.editedMaterial);
      if (this.editedMaterial.baseID =='0'){
        // Если этого материала нет в базе, то сохраняем его в базу
        this.editedMaterial.newTexture = this.editedMaterial.texture;
        const imageUrl = await this.saveImage(); // Сохраняем изображение и получаем URL
        this.editedMaterial.texture = imageUrl
        this.editedMaterial.imageUrl = imageUrl
        
        
        const result = await apiFetch('/material', {
          method: 'POST',
          body: JSON.stringify(this.editedMaterial),
        });
        this.editedMaterial.baseID = result.id;
        this.editedMaterial.isBase = true;

      }
      this.$emit("update", { ...this.editedMaterial });
    },
    async saveImage(){
      try{
        const result=await apiFetch('/save-image',{
          method: 'POST',
          body: JSON.stringify({
            image: this.editedMaterial.texture,
            }),
        })
        return (result.filename);
      }catch(err){
        console.log(err);
      }
    },
    onTextureUpload(event) {
      this.editedMaterial.color = 0xffffff; // Устанавливаем цвет в белый, если загружается текстура
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.editedMaterial.texture = e.target.result; // Сохраняем путь к изображению
          this.emitUpdate(); // Отправляем обновление родителю
        };
        reader.readAsDataURL(file); // Преобразуем изображение в data URL
      }
    },
    emitUpdate() {
      this.$emit("update", { ...this.editedMaterial }); // Отправляем обновления родителю
    }
  },
  watch: {
    material: {
      handler() {
        if (this.material.numOfMaterial !== this.editedMaterial.numOfMaterial) {
          this.editedMaterial = { ...this.material };
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 500px;
}
</style>
