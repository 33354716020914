<template>
    <div>
      <router-link to="/load-project" class="btn btn-primary btn-lg nav-link text-black my-2 px-4 py-2">
        Завантажить 3D проект
      </router-link>
      <h2>Моі проекти</h2>
  
      <!-- Список проектов -->
      <div v-if="projects.length > 0" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
        <div v-for="project in projects" :key="project.id" class="col">
          <!-- Ссылка на проект -->
          <router-link :to="`/project/${project.url}`" class="text-decoration-none">
            <div class="card shadow-sm h-100 project-card">
              <div class="row g-0">
                <!-- Изображение слева -->
                <div class="col-md-6">
                  <img :src="getImageUrl(project.image)" class="img-fluid rounded-start" alt="project image" />
                </div>
                <!-- Текст справа -->
                <div class="col-md-6">
                  <div class="card-body">
                    <h5 class="card-title">{{ project.name }}</h5>
                    <p class="card-text">{{ project.description }}</p>
                    <p class="card-text"><small class="text-muted">{{ project.category }}</small></p>
                  </div>
                </div>
              </div>
            </div>
        </router-link>
        </div>
      </div>
  
      <p v-else>Проектов пока нет.</p>
  
      <!-- Пагинация -->
      <nav v-if="totalPages > 1" aria-label="Навигация по страницам">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <button class="page-link" @click="changePage(currentPage - 1)">Предыдущая</button>
          </li>
  
          <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: page === currentPage }">
            <button class="page-link" @click="changePage(page)">{{ page }}</button>
          </li>
  
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <button class="page-link" @click="changePage(currentPage + 1)">Следующая</button>
          </li>
        </ul>
      </nav>
    </div>
  </template>
  <script>
  import { apiFetch } from '@/utils/api.js';
  export default {
    data() {
      return {
        projects: [],  // Список проектов
        currentPage: 1,  // Текущая страница
        totalPages: 1,   // Общее количество страниц
        limit: 20,        // Количество проектов на странице
      };
    },
    methods: {
      async fetchProjects() {
        try{
          const data = await apiFetch(`/project/user?page=${this.currentPage}&limit=${this.limit}`);
          console.log(data);
          this.projects = data.projects;
          this.totalPages = data.totalPages;
          this.currentPage = data.currentPage;

        }catch(err){
          console.error(err)
        }
      },
     
      changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchProjects();
      }
    },
      openProject(projectId) {
        this.$router.push(`/project/${projectId}`);
      },
      getImageUrl(imagePath) {
        return `${process.env.VUE_APP_API_URL}${imagePath}`;
      },
    },
    async mounted() {
      await this.fetchProjects();
      console.log(this.projects);
    },
  };
  </script>
  
  <style scoped>
  .card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .card-body {
    padding: 15px;
  }
  .card-title {
    font-size: 1.2em;
    font-weight: bold;
  }
  .card-text {
    color: #555;
  }

  .project-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .project-card:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  
  </style>