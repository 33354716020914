const API_URL = process.env.VUE_APP_API_URL

export async function apiFetch(endpoint, options = {}) {
  const token = localStorage.getItem('token'); // или sessionStorage

  const headers = {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }),
    ...options.headers
  };

  const config = {
    ...options,
    headers,
  };

  const response = await fetch(`${API_URL}${endpoint}`, config);

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(errorData.message || 'Ошибка запроса');
  }

  return response.json();
}
