<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <header class="bg-primary text-white py-3" ref="header">
      <nav class="navbar navbar-expand-lg navbar-dark container-fluid">
        <a class="navbar-brand" href="#">Мой сайт</a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" to="/" exact-active-class="active">Головна</router-link>
            </li>
           
            <li v-if="isUser()" class="nav-item">
              <router-link class="nav-link" to="/projects3d">3D Проекти</router-link>
            </li>
            
           
           
           
            <li v-if="isUser()" class="nav-item">
              <router-link class="nav-link" to="/profile">Профіль</router-link>
            </li>
            <li v-else class="nav-item">
              <router-link class="nav-link" to="/login">Войти</router-link>
            </li>
           
            <li v-if="isAdmin()" class="nav-item">
              <router-link class="nav-link" to="/admin">Адмінка</router-link>
            </li>
            <li v-if="isUser()" class="nav-item">
              <button class="nav-link btn btn-link" @click="logout">Выйти</button>
            </li>
          </ul>
        </div>
      </nav>
    </header>

    <main class="container-fluid py-1 flex-grow-1">
      <router-view />
    </main>
    
    <footer class="footer bg-dark text-white py-3 mt-auto" ref="footer">
      <div class="container text-center">
        <p>© 2025 Мой сайт. Все права защищены.</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')) || null,
      globalHeaderFooterHeight: 0,  // Глобальная переменная для высоты шапки и подвала
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('user');
      this.user = null;
      this.$router.push('/login');
    },
    isAdmin() {
      return this.user && this.user.role === 'admin';
    },
    isUser() {
      return !!this.user;
    },
    updateHeaderFooterHeight() {
      const headerHeight = this.$refs.header.offsetHeight; // Высота шапки
      //console.log(headerHeight);
      const footerHeight = this.$refs.footer.offsetHeight; // Высота подвала
      //console.log(footerHeight);
      this.globalHeaderFooterHeight = headerHeight + footerHeight+10; // Суммарная высота
      localStorage.setItem('globalHeaderFooterHeight', this.globalHeaderFooterHeight);
      //console.log("Header and Footer height:", this.globalHeaderFooterHeight); // Логирование для отладки
    },
  },
  mounted() {
    this.updateHeaderFooterHeight();  // Вычисление высоты шапки и подвала после монтирования компонента
    window.addEventListener('resize', this.updateHeaderFooterHeight);  // Слушаем изменение размера окна
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateHeaderFooterHeight); // Убираем обработчик при уничтожении компонента
  },
};
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  margin-top: auto;
}

.custom-dropdown {
  margin-right: 60px; /* Отступ от правого края */
}

.container-fluid {
  padding-left: 5px;
  padding-right: 5px;
 
}

main {
  padding-top: 10px; /* Это для того, чтобы контент не перекрывался шапкой */
}
</style>
