<template>  
  <div></div>
</template>

<script>
import * as THREE from "three";

export default {
  components: {
    
  },
 
  data() {
    return {
    };
  },
 
 
  methods: {
  async loadAndConvertTexture(url) {
    try {
      const image = await this.loadImage(url);

      // Оригинальная текстура
      const sRGBImageURL = this.convertToSRGB(image);
      const texture = await this.getTexture(sRGBImageURL);

      // Повернутая текстура
      const rotatedImageURL = this.rotateImage(image, 90);
      const rotatedTexture = await this.getTexture(rotatedImageURL);
      texture.encoding = THREE.sRGBEncoding;
      rotatedTexture.encoding = THREE.sRGBEncoding;

      return { original: texture, rotated: rotatedTexture };
    } catch (error) {
      console.error("Ошибка загрузки или обработки текстуры:", error);
    }
  },

  loadImage(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  },

  convertToSRGB(image) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0);
    return canvas.toDataURL();
  },

  rotateImage(image, angle) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (angle % 180 !== 0) {
      canvas.width = image.height;
      canvas.height = image.width;
    } else {
      canvas.width = image.width;
      canvas.height = image.height;
    }

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((angle * Math.PI) / 180);
    ctx.drawImage(image, -image.width / 2, -image.height / 2);

    return canvas.toDataURL();
  },

  async getTexture(textureURL) {
    if (!textureURL) {
      console.error("URL для текстуры отсутствует.");
      return null;
    }

    const loader = new THREE.ImageBitmapLoader();
    try {
      const imageBitmap = await loader.loadAsync(textureURL);
      const texture = new THREE.CanvasTexture(imageBitmap);
      texture.colorSpace = THREE.SRGBColorSpace;
      texture.wrapS = THREE.MirroredRepeatWrapping;
      texture.wrapT = THREE.MirroredRepeatWrapping;
      texture.repeat.set(1, 1);
      return texture;
    } catch (error) {
      console.error("Ошибка при загрузке текстуры:", error);
      return null;
    }
  }

}

}
</script>

