<template>
    <div>
        <FileUpload v-if="dataFromFile==null" @handleData="handleData" />
        <Project 
            v-if="dataFromFile!=null"
            :data="dataFromFile"
            :ViewLeftMenu="ViewLeftMenu"
        />
    </div>  
    
</template>

<script>
import FileUpload from "../components/threeComponents/fileUpload.vue";
import Project from "./ProjectComponent.vue";

export default {
  components: {
    FileUpload,
    Project
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      dataFromFile:null,
      ViewLeftMenu:{
        light:true,
        materials:true,
        blocks:true,
        panels:true,
        save:true,
        saveMaterial:true
      }
    };
  },
  mounted() {
    
  },
  methods: {
    async handleData(data){
        await this.checkMaterial(data.materials);
        this.dataFromFile=data;
    },
    async checkMaterial(materials) {
      for (const material of materials) {
              material.baseID=await this.searchMaterial(material.article);
          }
      },
    async searchMaterial(article) { //Поиск материала по артикулу
        try {
          const response = await fetch(`${this.apiUrl}/material/searchbyarticle?query=${article}`);
          if (!response.ok) throw new Error("Помилка завантаження матеріалу");
          const result = await response.json();
          if (result) {
            return (result.id)
          } else {
            return 0;
          }
         
        } catch (err) {
          console.log(err);
        }
      },
  },
}


</script>

