<template>  
    <div>
        
    </div>

    <div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasLights" aria-labelledby="offcanvasLightsLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasLightsLabel">Освітленя</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <div class="list-group">
            <div class="p-4 border border-primary rounded  text-center">
                <p>Ambient</p>
                <label for="ambient" class="form-label">Яркість ambient</label>
                <input 
                  type="range" 
                  class="form-range" 
                  id="ambient" 
                  min="0" 
                  max="5"  
                  step="0.01" 
                  v-model.number="light.ambient" 
                  @input="lightSetting()"
                />
                <label for="rotation" class="form-label">Вращать окружение</label>
                <input 
                  type="range" 
                  class="form-range" 
                  id="rotation" 
                  min="0" 
                  max="6.28"  
                  step="0.01" 
                  v-model.number="light.rotationAngle" 
                  @input="lightSetting()"
                />
                <label for="intensity" class="form-label">Яркість</label>
                <input 
                  type="range" 
                  class="form-range" 
                  id="intensity" 
                  min="0" 
                  max="5"  
                  step="0.01" 
                  v-model.number="light.intensity" 
                  @input="lightSetting()"
                />
                <label for="exposure" class="form-label">Экспозиция</label>
                <input 
                  type="range" 
                  class="form-range" 
                  id="exposure" 
                  min="0" 
                  max="2"  
                  step="0.01" 
                  v-model.number="light.exposure" 
                  @input="lightSetting()"
                />
              </div>
             
          </div>
          </div>
      </div>
</template>

<script>
// import * as THREE from "three";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        lightIntensity: 1,
        lightAmbient: 1,
        lightRotationAngle: 0,
        lightExposure: 1,
      }),
    },
  },
  data() {
    return {
      light: {
        intensity: 1,
        ambient: 1,
        rotationAngle: 0, // Угол в радианах
        exposure: 1,
      },
    };
  },
  mounted() {
    // Инициализация освещения с текущими значениями, если данные уже получены
    this.setLightData();
  },
  watch: {
    data() {
      // Если данные изменяются, обновляем параметры освещения
      //console.log("Данные изменились:", newData);
      this.setLightData();
    },
  },
  methods: {
    lightSetting() {
      this.$emit('lightSetting', this.light); // Передаем материал родителю
    },
    setLightData() {
      // Проверяем, что данные не null или undefined
      if (this.data) {
        //console.log("Данные для освещения:", this.data);

        // Обновляем параметры освещения на основе данных
        this.light.intensity = this.data.lightIntensity;
        this.light.ambient = this.data.lightAmbient;
        this.light.rotationAngle = this.data.lightRotationAngle;
        this.light.exposure = this.data.lightExposure;

        // Пауза перед отправкой данных родителю
        setTimeout(() => {
          //console.log("Передача данных родителю...");
          this.lightSetting(); // Передаем данные родителю
        }, 2000); // Пауза 2000 миллисекунд, можно настроить по нужде
      } else {
        console.log("Данные отсутствуют или еще не загружены.");
      }
    },
  },
};
</script>

<style>
.highlight {
    background-color: lightgray;
    cursor: pointer;
  }
</style>
