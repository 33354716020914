<template>  
    <div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasMaterials" aria-labelledby="offcanvasMaterialsLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasMaterialsLabel">Матеріали</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="list-group">
                <div 
                v-for="(item, index) in materials" 
                :key="index" 
                   
                class="list-group-item list-group-item-action">
                  <div class="d-flex w-100 justify-content-between">
                    <div class="row">
                      <div class="col-md-4">
                        <img 
                          v-if="item.newTexture" 
                          :src="item.newTexture" 
                          :alt="item.name" 
                          class="img-thumbnail" 
                          style="width: 100%; height: 100%;" 
                        />
                        <img 
                          v-else-if="item.imageUrl" 
                          :src="apiUrl + item.imageUrl" 
                          :alt="item.name" 
                          class="img-thumbnail" 
                          style="width: 100%; height: 100%;" 
                        />
                        <div 
                          v-else-if="item.color" 
                          class="img-thumbnail" 
                          :style="{ width: '100%', height: '100%', backgroundColor: `#${item.color.toString(16).padStart(6, '0')}` }"
                        >
                         
                        </div>
                      </div>
                      
                      
                      
                      <div class="col-md-8">
                        <h5 class="mb-1">{{ item.alias }}</h5>
                        <h5 class="mb-1">{{ item.name }}</h5>
                        <small class="text-body-secondary">{{ item.article }}</small>
                        <button class="btn btn-primary mt-3" @click="openModal(item)">
                          Редагувати
                        </button>
                        
                      </div>
                      
                    </div>
                    
                  </div>
                </div>
              </div>
          </div>
      </div>
      <!-- Bootstrap 5 Modal -->
      <div
  class="offcanvas offcanvas-start"  
  tabindex="-1"
  id="offcanvasLeft"
  aria-labelledby="offcanvasLeftLabel"
  ref="offcanvasEl"
>
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasLeftLabel">Модальное окно</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <EditMaterial 
      v-if="materialEditData"
      :material="materialEditData"
      :saveMaterial="saveMaterial"
      @update="onMaterialChange"
      @save="onMaterialSave"
    />
  </div>
</div>

    
      <LoadTextureComponent ref="materialEdit" />
</template>

<script>
import { Offcanvas } from 'bootstrap';
import * as THREE from "three";
import LoadTextureComponent from "./LoadTexture.vue"
import EditMaterial from "./EditMaterial.vue"
export default {
  emits: ['updateMaterial'], // Добавляем событие в список разрешённых
  components: {
   
    LoadTextureComponent,
    EditMaterial
  },
  props: {
    data: Array, 
    saveMaterial: Boolean,
  },
  data() {
    return {
      materials:[],
      materialEditData:null, 
      apiUrl: process.env.VUE_APP_API_URL,
      offcanvasInstance: null,
    };
  },
  
  mounted() {
    //console.log("Materials Начальные данные:", this.data);
    this.addMaterials(this.data);
    this.materials = this.data;
    const offcanvasEl = this.$refs.offcanvasEl;
    this.offcanvasInstance = new Offcanvas(offcanvasEl, { backdrop: false });  // Убираем блокировку (backdrop: false)
    //this.returnData(this.materialMeshes);
  },
  methods: {
    openModal(item) {
        this.materialEditData=item;
        this.offcanvasInstance.show();  // Показываем offcanvas при монтировании компонента
    },
    async onMaterialChange(updatedMaterial) {
  for (const material of this.materials) {
    if (material.numOfMaterial === updatedMaterial.numOfMaterial) {
    
      material.newTexture = updatedMaterial.newTexture;
      material.color = updatedMaterial.color;
      material.alias = updatedMaterial.alias;
      material.baseID = updatedMaterial.baseID;
      // Обновляем основные параметры материала
      //console.log("Обновленній  материал:", material);
      //console.log("Обновленния", updatedMaterial);
      material.threeMaterial.color.set(updatedMaterial.color);
      material.threeMaterial.roughness = updatedMaterial.roughness;
      material.threeMaterial.metalness = updatedMaterial.metalness;
      material.threeMaterial.opacity = updatedMaterial.opacity;
      material.threeMaterial.transparent = updatedMaterial.opacity < 1;
      material.threeMaterial.reflectivity = updatedMaterial.reflectivity;
      material.threeMaterial.clearcoat = updatedMaterial.clearcoat;
      material.threeMaterial.clearcoatRoughness = updatedMaterial.clearcoatRoughness;
      material.threeMaterial.ior = updatedMaterial.ior;
      material.threeMaterial.transmission = updatedMaterial.transmission;
      material.threeMaterial.envMapIntensity = updatedMaterial.envMapIntensity;

      material.threeMaterial90.color.set(updatedMaterial.color);
      material.threeMaterial90.roughness = updatedMaterial.roughness;
      material.threeMaterial90.metalness = updatedMaterial.metalness;
      material.threeMaterial90.opacity = updatedMaterial.opacity;
      material.threeMaterial90.transparent = updatedMaterial.opacity < 1;
      material.threeMaterial90.reflectivity = updatedMaterial.reflectivity;
      material.threeMaterial90.clearcoat = updatedMaterial.clearcoat;
      material.threeMaterial90.clearcoatRoughness = updatedMaterial.clearcoatRoughness;
      material.threeMaterial90.ior = updatedMaterial.ior;
      material.threeMaterial90.transmission = updatedMaterial.transmission;
      material.threeMaterial90.envMapIntensity = updatedMaterial.envMapIntensity;

      // Обновляем текстуру, если она была изменена
      if (updatedMaterial.texture) {
        console.log("Обновляем текстуру:", updatedMaterial.texture);
        const result = await this.$refs.materialEdit.loadAndConvertTexture(updatedMaterial.texture);
        console.log("Результат из дочернего компонента:", result);
        if (result) {
          material.threeMaterial.map = result.original;
          material.threeMaterial.map.needsUpdate = true;
          material.threeMaterial90.map = result.rotated;
          material.threeMaterial90.map.needsUpdate = true;
        }
       
        
      }

      // Устанавливаем флаг для обновления материала в three.js
      material.threeMaterial.needsUpdate = true;
      material.threeMaterial90.needsUpdate = true;
    }
  }
},
 

  onMaterialSave(updatedMaterial) {
    console.log("Сохранён материал:", updatedMaterial);
    
    // Сохранить в базе или отправить на сервер
  },
 
  
  async addMaterials(materials) {

  for (const material of materials) {
    //console.log("Материал:", material);
    if (material.baseID !='0'){
      const dataMaterial = await this.getMaterial(material.baseID);
      if (dataMaterial) {
        // Материал найден в базе
        Object.assign(material, dataMaterial);
        material.color = parseInt(material.color);

        const newMaterials = await this.createMaterial(material);
        //console.log("Создан новый материал:", newMaterials);
        material.isBase = true;
        material.threeMaterial = newMaterials.newMaterial;
        material.threeMaterial90 = newMaterials.newMaterial90;
        material.threeMaterial.needsUpdate = true;
        material.threeMaterial90.needsUpdate = true;

        //console.log("Материал из базы:");
        //console.log(material);
      }
    }else if (material.article) {
      material.alias = material.name;
      const dataMaterial = await this.searchMaterial(material.article);

      if (dataMaterial) {
        // Материал найден в базе
        Object.assign(material, dataMaterial);

        const newMaterials = await this.createMaterial(material);
        material.isBase = true;
        material.threeMaterial = newMaterials.newMaterial;
        material.threeMaterial90 = newMaterials.newMaterial90;

        //console.log("Материал из базы:");
        //console.log(material);
      } else {
        // Артикул есть, но в базе не найден — создаём как пустой
        const newMaterials = await this.createNullMaterial(material);
        material.isBase = false;
        material.threeMaterial = newMaterials.newMaterial;
        material.threeMaterial90 = newMaterials.newMaterial90;

        //console.log("Артикул есть, но материал не найден. Создан NULL материал:");
        //console.log(material);
      }

    } else {
      material.alias = material.name;
      // Артикула нет — создаём пустой материал
      const newMaterials = await this.createNullMaterial(material);
      material.isBase = false;
      material.threeMaterial = newMaterials.newMaterial;
      material.threeMaterial90 = newMaterials.newMaterial90;

      //console.log("NULL материал без артикула:");
      //console.log(material);
    }

    // Обновляем родительский компонент
    this.$emit('updateMaterial', material);
  }
},


async createMaterial(material) {
    // Создаём экземпляр материала
   // console.log("---------------Создание материала:", material);
    material.envMapIntensity = 0.3;
        const newMaterial = new THREE.MeshPhysicalMaterial({
            roughness: material.roughness,
            metalness: material.metalness,
            clearcoat: material.clearcoat,
            clearcoatRoughness: material.clearcoatRoughness,
            reflectivity: material.reflectivity,
            opacity: material.opacity ?? 1, // Используем 1, если opacity отсутствует
            color: new THREE.Color(material.color), // Убедимся, что создаём объект цвета
            ior: material.ior, // Индекс преломления
            transmission: material.transmission, // Прозрачность
            envMapIntensity: material.envMapIntensity, // Интенсивность окружающего освещения
            transparent: material.opacity < 1, // Делаем материал прозрачным, если opacity меньше 1
        });

        const newMaterial90 = new THREE.MeshPhysicalMaterial({
            roughness: material.roughness,
            metalness: material.metalness,
            clearcoat: material.clearcoat,
            clearcoatRoughness: material.clearcoatRoughness,
            reflectivity: material.reflectivity,
            opacity: material.opacity ?? 1, // Используем 1, если opacity отсутствует
            color: new THREE.Color(material.color), // Убедимся, что создаём объект цвета
            ior: material.ior, // Индекс преломления
            transmission: material.transmission, // Прозрачность
            envMapIntensity: material.envMapIntensity, // Интенсивность окружающего освещения
            transparent: material.opacity < 1, // Делаем материал прозрачным, если opacity меньше 1
        });

        // Применяем текстуру
        if (material.texture && material.texture !='/uploads/void.jpg') {
          //console.log("Текстура:", material.texture);
          if (this.$refs.materialEdit) {
            const result = await this.$refs.materialEdit.loadAndConvertTexture(this.apiUrl+material.texture);
            newMaterial.map = result.original;
            newMaterial.map.needsUpdate = true;
            newMaterial90.map = result.rotated;
            newMaterial90.map.needsUpdate = true;
            //console.log("Результат из дочернего компонента:", result);
          }
            
        }

        // Указываем, что материал нужно обновить
        newMaterial.needsUpdate = true;
        newMaterial90.needsUpdate = true;

        // Возвращаем готовый материал
        return {newMaterial,newMaterial90};
    },
    async createNullMaterial(material) {  //если в базе нет материала
    // Создаём экземпляр материала
        const nullDada = {
            roughness: 1,
            metalness: 1,
            clearcoat: 0,
            clearcoatRoughness: 0.17,
            reflectivity: 0.85,
            opacity: 1,
            color: 0xFF0000, // Убедимся, что создаём объект цвета
            ior: 0, // Индекс преломления
            transmission: 0, // Прозрачность
            envMapIntensity: 1, // Интенсивность окружающего освещения
        };
        Object.assign(material, nullDada);
        const newMaterial = await this.createMaterial(material);
        
        // Возвращаем готовый материал
        return newMaterial;
    },

  async searchMaterial(article) { //Поиск материала по артикулу
        try {
          const response = await fetch(`${this.apiUrl}/material/searchbyarticle?query=${article}`);
          if (!response.ok) throw new Error("Помилка завантаження матеріалу");
          const result = await response.json();
          return (result);
        } catch (err) {
          console.log(err);
        }
      },
  async getMaterial(id) { //Получаем материал по id
        try {
          const response = await fetch(`${this.apiUrl}/material/${id}`);
          if (!response.ok) throw new Error("Помилка завантаження матеріалу");
          const result = await response.json();
          return (result);
        } catch (err) {
          console.log(err);
        }
      },

    
  }
}
</script>
<style>
.highlight {
    background-color: lightgray;
    cursor: pointer;
  }
</style>
