<template>  
    <div>
        
    </div>

    <div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasSave" aria-labelledby="offcanvasSaveLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasSaveLabel">Зберегти</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <div class="row">
            <div class="input-group mb-3">
              <img 
              :src="data.screenshot" 
              class="img-thumbnail" 
              style="width: 100%; height: 100%;" 
            />
            </div>
            <div class="mb-3">
              <div v-if="error" class="text-primary-emphasis bg-danger-subtle border border-danger-subtle rounded-1" style="white-space: pre-line;">
                {{ error }}
              </div>
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1">Назва</span>
              <input type="text" 
                class="form-control" 
                aria-label="Name" 
                aria-describedby="basic-addon1"
                v-model="name"
              />
            </div>
            <div class="input-group mb-3">
              <div class="form-check">
                <input class="form-check-input" 
                  type="checkbox"
                  id="isHome"
                  v-model="isHome"
                  >
                <label class="form-check-label" for="isHome">
                  На головній
                </label>
              </div>
            </div>
            <div class="input-group">
              <span class="input-group-text">Опис</span>
              <textarea class="form-control" 
                aria-label="With textarea"
                v-model="description"
                />
            </div>
            <div class="input-group">
              <button type="button" class="btn btn-success" @click="saveData">
                Зберегти
              </button>
            </div>
            
          </div>
           
          
          </div>
      </div>
      <div 
        v-if="isLoading" 
        class="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-light bg-opacity-75 z-3"
        >
        <div class="text-center">
          <span class="spinner-border text-primary mb-3" role="status" aria-hidden="true"></span>
          <p>ЗБЕРЕЖЕННЯ</p>
        </div>
      </div>
</template>

<script>
import { Offcanvas } from 'bootstrap';
import { apiFetch } from '@/utils/api.js';

export default {
  props: {
    data: Object, 
  },
  data() {
    return {
      isLoading:false,
      error:null,
     name:'',
     description:'',
     isHome:false,
     //materials,
     apiUrl: process.env.VUE_APP_API_URL,
    };
  },
 
  mounted() {
    console.log("Начальные данные Save:", this.data);
    const offcanvasElement = document.getElementById("offcanvasSave");
    if (offcanvasElement) {
      const offcanvasInstance = new Offcanvas(offcanvasElement);
      // Делаем небольшую задержку перед показом
      setTimeout(() => {
          offcanvasInstance.show();
        }, 100); // 100ms задержки для плавного старта анимации

    }
    // Отслеживаем закрытие Offcanvas и уведомляем родителя
    offcanvasElement.addEventListener("hidden.bs.offcanvas", () => {
        this.$emit("closeSave");
      });
    
     this.isLoading=true;
     //this.materials = this.data.materials;
     this.error = this.errorMAterials(this.data.materials);
     this.isLoading=false; 
   
  },
  methods: {
    errorMAterials(materials){
      let error = 'Додайте матеріал:\n';
      materials.forEach(material=>{
        if (!material.isBase){
          error=error+material.name + '\n' ;
        }
      })
      if (error == 'Додайте матеріал:\n'){
        return null
      }else{
        return error
      }
    },
    async saveData(){
      console.log('Save Data');
      this.isLoading=true;
      const imageName = await this.saveImage();
      const id = await this.createProject(imageName);
      await this.saveMaterials(id);
      await this.savePanels(id);
      await this.saveExtrusions(id);
      await this.saveBlocks(id);
      this.isLoading=false;
    },
    async saveMaterials(id){
      try{
        await apiFetch('/projectmaterial',{
          method: 'POST',
          body: JSON.stringify({
            projectId:id,
            materials:this.data.materials,
          })
        })
      }catch(err){
        console.log(err);
      }
    },
    async savePanels(id){
      try{
        if (this.data.panels.length == 0){
          return
        }
        await apiFetch('/panel/bulk',{
          method: 'POST',
          body: JSON.stringify({
            projectId:id,
            panels:this.data.panels,
          })
        })
      }catch(err){
        console.log(err);
      }
    },
    async saveExtrusions(id){
      try{
        if (this.data.extrusions.length == 0){
          return
        }
        await apiFetch('/extrusion/bulk',{
          method: 'POST',
          body: JSON.stringify({
            projectId:id,
            extrusions:this.data.extrusions,
          })
        })
      }catch(err){
        console.log(err);
      }
    },
    async saveBlocks(id){
      try{
        if (this.data.blocks.length == 0){
          return
        }
        await apiFetch('/block/bulk',{
          method: 'POST',
          body: JSON.stringify({
            projectId:id,
            blocks:this.data.blocks,
          })
        })
      }catch(err){
        console.log(err);
      }
    },
    async saveImage(){
      try{
        const result=await apiFetch('/save-image',{
          method: 'POST',
          body: JSON.stringify({
            image: this.data.screenshot,
            pre:'project_'
            }),
        })
        return (result.filename);
      }catch(err){
        console.log(err);
      }
    },
    async createProject(imageName) {
      try {
        const result = await apiFetch('/project', {
          method: 'POST',
          body: JSON.stringify({
            name: this.name,
            description: this.description,
            image: imageName,
            isHome: this.isHome,
            cameraPosX: this.data.camera.position.x,
            cameraPosY: this.data.camera.position.y,
            cameraPosZ: this.data.camera.position.z,
            cameraLookAtX: this.data.camera.target.x,
            cameraLookAtY: this.data.camera.target.y,
            cameraLookAtZ: this.data.camera.target.z,
            lightIntensity: this.data.light.intensity,
            lightAmbient: this.data.light.ambient,
            lightRotationAngle: this.data.light.rotationAngle,
            lightExposure: this.data.light.exposure,
              }),
        });
        return(result.id);
      } catch (err) {
        console.error(err);
        this.error = err.message;
      }
    }
},

}
</script>
<style>
.highlight {
    background-color: lightgray;
    cursor: pointer;
  }
</style>
