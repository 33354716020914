<template>  
    <div>
        <div class="position-absolute top-40 start-20 m-3 z-3 d-flex flex-column gap-2">
            <button 
                v-if ="ViewLeftMenu.light"
                class="btn btn-primary" 
                type="button" 
                data-bs-toggle="offcanvas" 
                data-bs-target="#offcanvasLights" 
                aria-controls="offcanvasLights"
                >
                <i class="bi bi-bricks"></i>
                Освітленя
            </button>    
            <button 
                v-if ="ViewLeftMenu.materials"
                class="btn btn-primary" 
                type="button" 
                data-bs-toggle="offcanvas" 
                data-bs-target="#offcanvasMaterials" 
                aria-controls="offcanvasMaterials"
                >
                <i class="bi bi-bricks"></i>
                Матеріали
            </button> 
            <button 
                v-if ="ViewLeftMenu.blocks"
                class="btn btn-primary" 
                type="button" 
                data-bs-toggle="offcanvas" 
                data-bs-target="#offcanvasBlocks" 
                aria-controls="offcanvasBlocks"
                >
                <i class="bi bi-bricks"></i>
                Блоки
            </button>  
            <button 
                v-if ="ViewLeftMenu.panels"
                class="btn btn-primary" 
                type="button" 
                data-bs-toggle="offcanvas" 
                data-bs-target="#offcanvasPanels" 
                aria-controls="offcanvasPanels"
                >
                <i class="bi bi-bricks"></i>
                Панелі
            </button>    
            <button 
                v-if ="ViewLeftMenu.save"
                class="btn btn-primary" 
                type="button"            
                @click="saveData"  
              >
                <i class="bi bi-bricks"></i>
                Зберегти
              </button>
                            
       </div>
    </div>

    
</template>

<script>
export default {
  props: {
    ViewLeftMenu:Object,
  },
  methods: {
    saveData() {
      // Выполняем emit события в родительский компонент
      console.log("SaveToServer");
      this.$emit('saveToServer'); // Событие 'save' будет отправлено родителю
    }
  }
}
</script>
<style>

</style>
