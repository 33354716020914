
import TodoPageVue from './TodoPage.vue';
<template>
  <div class="d-flex align-items-start">
    <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
      <button 
        class="nav-link" 
        :class="{ active: activeTab === 'home' }" 
        @click="activeTab = 'home'">
        Home
      </button>
      <button 
        class="nav-link" 
        :class="{ active: activeTab === 'users' }" 
        @click="activeTab = 'users'">
        Користувачі
      </button>
      <button 
        class="nav-link" 
        :class="{ active: activeTab === 'materials' }" 
        @click="activeTab = 'materials'">
        Матеріали
      </button>
      <button 
        class="nav-link" 
        :class="{ active: activeTab === 'projects' }" 
        @click="activeTab = 'projects'">
        Проекти
      </button>
      <button 
        class="nav-link" 
        :class="{ active: activeTab === 'swttings' }" 
        @click="activeTab = 'settings'">
        Налаштування
      </button>
      <button 
        class="nav-link" 
        :class="{ active: activeTab === 'todo' }" 
        @click="activeTab = 'todo'">
        ToDo
      </button>
    </div>

    <div class="tab-content flex-grow-1">
      <HomeTab v-if="activeTab === 'home'" />
      <UsersTab v-if="activeTab === 'users'" />
      <MaterialsTab v-if="activeTab === 'materials'" />   
      <ProjectsTab v-if="activeTab === 'projects'" />
      <ToDoTab v-if="activeTab === 'todo'" />
      <SettingsTab v-if="activeTab === 'settings'" />
    </div>
  </div>
</template>

<script>
import HomeTab from '../components/admin/HomeTab.vue';
import UsersTab from '../components/admin/UsersTab.vue';
import MaterialsTab from '../components/admin/MaterialsTab.vue';
import ProjectsTab from '../components/admin/ProjectsTab.vue';
import ToDoTab from '../components/admin/ToDoPage.vue'
import SettingsTab from '../components/admin/SettingsTab.vue'

export default {
  components: {
    HomeTab,
    UsersTab,
    MaterialsTab, 
    ProjectsTab,
    ToDoTab,
    SettingsTab,
  },
  data() {
    return {
      activeTab: 'home', // Начальная вкладка
    };
  },
};
</script>

<style>
.nav-link.active {
  background-color: #0d6efd;
  color: white;
}
</style>
