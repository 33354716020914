import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage';


import MaterialPage from '../views/MaterialPage.vue'; // Импорт компонента для страницы материала
import RegisterPage from '../views/RegisterPage.vue';
import LoginPage from '../views/LoginPage.vue';
import AdminPage from '@/views/AdminPage.vue';

import ProjectPage from '@/views/ProjectPage.vue';
import ProfilePage from '@/views/ProfilePage.vue';

import Projects3d from '@/views/Projects3dComponent.vue'; // Импорт компонента для страницы 3D проектов
import Project3d from '@/ThreeScene/ViewProject.vue';
import LoadProject from '@/ThreeScene/AddProject.vue'

const routes = [
  {
    path: '/project/:url',  // Параметр id
    name: 'Project3d', 
    component: Project3d,
    props: true  // Передаем параметр как prop в компонент
  },
  {
    path: "/load-project",
    name: "load-project",
    component: LoadProject,
  },
  {
    path: "/projects3d",
    name: "projects3d",
    component: Projects3d,
  },

  {
    path: '/profile',
    name: 'Profile',
    component: ProfilePage,
    meta: { requiresAuth: true }, // Доступ только для авторизованных пользователей
  },
  {   // Страница редактирования проекта
    path: '/project/:id',
    name: 'Project',
    component: ProjectPage,
    props: true,
    meta: { requiresAuth: true }, // Доступ только для авторизованных пользователей
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminPage,
    meta: { requiresAuth: true, isAdmin: true },
  },
  { path: '/', component: HomePage },



  { path: '/materials/:id', component: MaterialPage, props: true }, // Динамический маршрут для материала
  { path: '/register', name: 'register', component: RegisterPage },
  { path: '/login', name: 'login', component: LoginPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Пример проверки авторизации и роли администратора
router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user')); // пример хранения токена
  if (to.meta.requiresAuth && !user) {
    return next('/login');
  }
  if (to.meta.isAdmin && user?.role !== 'admin') {
    return next('/');
  } 
  next();
});

export default router;
