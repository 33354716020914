<template>
    <div>
      <form @submit.prevent="addMaterial">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="newMaterial.url"
            placeholder="URL"
          />
          <button class="btn btn-primary" type="submit" :disabled="isLoading">
            {{ isLoading ? "Завантаження..." : "Додати матеріал" }}
          </button>
        </div>
      </form>
      <!-- Спиннер -->
      <div v-if="isLoading" class="spinner-container">
        <div class="spinner-border text-primary" role="status">
          <span></span>
        </div>
        {{materialLoading}}
      </div>
  
      <!-- Ошибка -->
      <p v-if="error" class="text-danger">{{ error }}</p>
      
      <!-- Карточки материалов-->
      <div class="row row-cols-1 row-cols-md-6 g-4">
        <div class="col" v-for="material in materials" :key="material.id">
          <a 
          :href="'materials/' + material.id"
            target="_blank" 
            style="text-decoration: none;">
            <div class="card">
              <img 
                :src="apiUrl + material.imageUrl" 
                :alt="material.name" 
                class="img-thumbnail" 
                style="width: 100%; height: 100%;" 
              />
  
              <div class="card-body">
                <p class="card-text">
                  Код: 
                  <span class="text-primary" >{{ material.article }}</span>
                </p>
                <h5 class="card-title">{{ material.name }}</h5>
                <p class="card-text">Ціна: {{ material.price }}</p>
              </div>
            </div>
          </a>
        </div>     
      </div>
    </div>
  </template>
  
    
    <script>
    
    export default {
      name: "MaterialPage",
      data() {
        return {
          newMaterial: { url: "" },
          materials: [],
          isLoading: false,
          error: null,
          materialLoading:"",
          apiUrl: process.env.VUE_APP_API_URL 
        };
      },
      methods: {
        
        async fetchMaterials() {
          try {
            
            this.isLoading = true;
            const response = await fetch(`${this.apiUrl}/material`);
            if (!response.ok) throw new Error("Помилка загрузки матеріалу");
            this.materials = await response.json();
          } catch (err) {
            this.error = err.message;
          } finally {
            this.isLoading = false;
          }
        },
        async addMaterial() {
          if (!this.newMaterial.url.trim()) return;
          try {
            this.materialLoading = "Завантажую матеріал"
            this.isLoading = true;
            const response = await fetch(`${this.apiUrl}/material/viyar`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                url: this.newMaterial.url.trim(),
              }),
            });
            if (!response.ok) throw new Error("Помилка додавання матеріалу");
            const newTask = await response.json();
            this.materials.push(newTask.material);
            console.log(newTask);         
            this.newMaterial = { url: "" };
          } catch (err) {
            this.error = err.message;
          } finally {
            this.isLoading = false;
            this.materialLoading = ""
          }
        },
      },
      async created() {
        await this.fetchMaterials();
      },
    };
    </script>
    
    <style scoped>
    h5 {
      margin: 0;
    }
    .text-decoration-line-through {
      text-decoration: line-through;
    }
    </style>
    